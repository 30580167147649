export const pics = [
  {
    name: "picture_1.jpg",
    type: "file",
  },
  {
    name: "picture_10.jpg",
    type: "file",
  },
  {
    name: "picture_11.jpg",
    type: "file",
  },
  {
    name: "picture_12.jpg",
    type: "file",
  },
  {
    name: "picture_13.jpg",
    type: "file",
  },
  {
    name: "picture_14.jpg",
    type: "file",
  },
  {
    name: "picture_15.jpg",
    type: "file",
  },
  {
    name: "picture_16.jpg",
    type: "file",
  },
  {
    name: "picture_17.jpg",
    type: "file",
  },
  {
    name: "picture_18.jpg",
    type: "file",
  },
  {
    name: "picture_19.jpg",
    type: "file",
  },
  {
    name: "picture_20.jpg",
    type: "file",
  },
  {
    name: "picture_21.jpg",
    type: "file",
  },
  {
    name: "picture_22.jpg",
    type: "file",
  },
  {
    name: "picture_23.jpg",
    type: "file",
  },
  {
    name: "picture_24.jpg",
    type: "file",
  },
  {
    name: "picture_25.jpg",
    type: "file",
  },
  {
    name: "picture_26.jpg",
    type: "file",
  },
  {
    name: "picture_27.jpg",
    type: "file",
  },
  {
    name: "picture_28.jpg",
    type: "file",
  },
  {
    name: "picture_29.jpg",
    type: "file",
  },
  {
    name: "picture_3.jpg",
    type: "file",
  },
  {
    name: "picture_30.jpg",
    type: "file",
  },
  {
    name: "picture_31.jpg",
    type: "file",
  },
  {
    name: "picture_32.jpg",
    type: "file",
  },
  {
    name: "picture_33.jpg",
    type: "file",
  },
  {
    name: "picture_34.jpg",
    type: "file",
  },
  {
    name: "picture_35.jpg",
    type: "file",
  },
  {
    name: "picture_36.jpg",
    type: "file",
  },
  {
    name: "picture_37.jpg",
    type: "file",
  },
  {
    name: "picture_38.jpg",
    type: "file",
  },
  {
    name: "picture_39.jpg",
    type: "file",
  },
  {
    name: "picture_4.jpg",
    type: "file",
  },
  {
    name: "picture_40.jpg",
    type: "file",
  },
  {
    name: "picture_41.jpg",
    type: "file",
  },
  {
    name: "picture_43.jpg",
    type: "file",
  },
  {
    name: "picture_44.jpg",
    type: "file",
  },
  {
    name: "picture_45.jpg",
    type: "file",
  },
  {
    name: "picture_47.jpg",
    type: "file",
  },
  {
    name: "picture_48.jpg",
    type: "file",
  },
  {
    name: "picture_49.jpg",
    type: "file",
  },
  {
    name: "picture_5.jpg",
    type: "file",
  },
  {
    name: "picture_50.jpg",
    type: "file",
  },
  {
    name: "picture_51.jpg",
    type: "file",
  },
  {
    name: "picture_52.jpg",
    type: "file",
  },
  {
    name: "picture_7.jpg",
    type: "file",
  },
  {
    name: "picture_8.jpg",
    type: "file",
  },
  {
    name: "picture_9.jpg",
    type: "file",
  },
].map(({ name }) => {
  return { source: `imgs/engagement/${name}` };
});

export const pics_thumbs = pics.map((el) => {
  return {
    source: el.source.replace(".", "_thumbnail."),
  };
});
