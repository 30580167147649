import logo from "./logo.svg";
import "./App.css";
import MainNavigation from "./Component/Nav";
import Main from "./Page/Main";
import { createRoot } from "react-dom/client";
import { Route, Routes, useNavigate } from "react-router-dom";
import { Modal, Box, Typography } from "@mui/material";
import routes from "./Base/routes";
import Photos from "./Page/Photos";
import OurStory from "./Page/OurStory";
import FAQ from "./Page/FAQ";
import PlayList from "./Page/PlayList";
import { useEffect } from "react";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
import colors from "./Base/colors";
import fonts from "./Base/fonts";
import QrCodeIcon from "@mui/icons-material/QrCode";
import QRCode from "react-qr-code";
import MobileFriendlyIcon from "@mui/icons-material/MobileFriendly";
import Rsvp from "./Page/Rsvp";
import Registry from "./Page/Registry";

function App() {
  const navigate = useNavigate();
  const changeRoute = (routeExtension) => {
    console.log("clicked", routeExtension);
    navigate(routeExtension);
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  };
  useEffect(() => {
    const el = document.getElementById("spinner-state");
    if (el) {
      el.remove();
    }
  }, []);

  return (
    <div>
      <MainNavigation onClick={changeRoute} />
      <Routes>
        <Route exact path="/" element={<Main />} />
        <Route exact path={routes.HOME} element={<Main />} />
        <Route exact path={routes.PHOTOS} element={<Photos />} />
        <Route exact path={routes.OUT_STORY} element={<OurStory />} />
        <Route exact path={routes.FAQ} element={<FAQ />} />
        <Route exact path={routes.PLAYLISTS} element={<PlayList />} />
        <Route exact path={routes.RSVP} element={<Rsvp />} />
      </Routes>
      <Modal
        open={!isMobile}
        onClose={() => {}}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ width: "100vw", height: "100vh" }}
        disableAutoFocus={true}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 800,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 3,
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              width: "100%",
              justifyContent: "center",
            }}
          >
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              color={colors.PRIMARY}
              fontFamily={fonts.NORMAL}
              fontWeight={800}
              sx={{ textAlign: "center" }}
            >
              This Website is Mobile Only!
            </Typography>
            <MobileFriendlyIcon
              sx={{ color: colors.PRIMARY }}
            ></MobileFriendlyIcon>
          </div>

          <Typography
            id="modal-modal-description"
            sx={{ mt: 2 }}
            color={colors.TEXT}
            fontFamily={fonts.NORMAL}
            fontWeight={800}
          >
            Dear User, <br></br>I apologize for the inconvinence, but I desgined
            this application from the ground up to be experienced in mobile.
          </Typography>
          <Typography
            id="modal-modal-description"
            color={colors.TEXT}
            fontFamily={fonts.NORMAL}
            fontWeight={800}
          >
            Please Scan this QR code below using your camera :
          </Typography>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              paddingTop: "24px",
            }}
          >
            {" "}
            <QRCode
              value={`https://carelysandahmad.com/${window.location.hash}`}
            />
          </div>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2 }}
            color={colors.TEXT}
            fontFamily={fonts.NORMAL}
            fontWeight={800}
          >
            or manually type in
            <a
              href="https://carelysandahmad.com"
              style={{ paddingLeft: "2px" }}
            >
              carelysandahmad.com
            </a>{" "}
            <br></br> <br></br>
            Best,<br></br>
            Ahmad Baderkhan
          </Typography>
        </Box>
      </Modal>
    </div>
  );
}

export default App;
