import React, { useState, useMemo, useRef, useEffect } from "react";
import useInterval from "use-interval";
import TinderCard from "react-tinder-card";
import TypewriterComponent from "typewriter-effect";
import Stories from "react-insta-stories";
import fonts from "../Base/fonts";
import { Navigate } from "react-router-dom";
import routes from "../Base/routes";
import { useTimeout } from "react-use-timeout";
import { useNavigate } from "react-router-dom";
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from "body-scroll-lock";

const db = [
  {
    name: "Ahmad 23",
    url: "./imgs/our-story/me_tinder.jpg",
  },
  {
    name: "Carelys 25",
    url: "./imgs/our-story/her_tinder.jpg",
  },
];

function Advanced({ show = false }) {
  const [currentIndex, setCurrentIndex] = useState(db.length - 1);
  const [shouldGo, setShouldGo] = useState(false);

  // used for outOfFrame closure
  const currentIndexRef = useRef(currentIndex);

  const childRefs = useMemo(
    () =>
      Array(db.length)
        .fill(0)
        .map((i) => React.createRef()),
    []
  );

  const updateCurrentIndex = (val) => {
    setCurrentIndex(val);
    currentIndexRef.current = val;
  };

  useInterval(async () => {
    if (currentIndex < 0) {
      return;
    }
    if (shouldGo) {
      console.log("swiping");
      await swipe("right");
    }
    setShouldGo(true);
  }, 1500);

  // set last direction and decrease current index
  const swiped = (direction, nameToDelete, index) => {
    updateCurrentIndex(index - 1);
  };

  const outOfFrame = (name, idx) => {
    console.log(`${name} (${idx}) left the screen!`, currentIndexRef.current);
    // handle the case in which go back is pressed before card goes outOfFrame
    currentIndexRef.current >= idx && childRefs[idx].current.restoreCard();
    // TODO: when quickly swipe and restore multiple times the same card,
    // it happens multiple outOfFrame events are queued and the card disappear
    // during latest swipes. Only the last outOfFrame event should be considered valid
  };

  const swipe = async (dir) => {
    await childRefs[currentIndex].current.swipe(dir); // Swipe the card!
  };

  return show ? (
    <div style={{ width: "100%", padding: 20 }}>
      <div
        id="main_tinder"
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          paddingTop: "40px",
        }}
      >
        <link
          href="https://fonts.googleapis.com/css?family=Damion&display=swap"
          rel="stylesheet"
        />
        <link
          href="https://fonts.googleapis.com/css?family=Alatsi&display=swap"
          rel="stylesheet"
        />
        <div className="cardContainer">
          {db.map((character, index) => (
            <TinderCard
              ref={childRefs[index]}
              className="swipe"
              key={character.name}
              onSwipe={(dir) => {
                return swiped(dir, character.name, index);
              }}
              onCardLeftScreen={() => outOfFrame(character.name, index)}
            >
              <div
                style={{ backgroundImage: "url(" + character.url + ")" }}
                className="card"
              >
                <h3>{character.name}</h3>
              </div>
            </TinderCard>
          ))}
        </div>
      </div>
      <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <div
          style={{
            width: "60%",
            display: "flex",
            justifyContent: "center",
            fontFamily: fonts.NORMAL,
            fontWeight: "800",
            paddingTop: "20px",
            height: "150px",
            color: "white",
          }}
        >
          <TypewriterComponent
            options={{
              strings: ["It all began with a swipe!"],
              autoStart: true,
              delay: 50,
              pauseFor: 3500,
              deleteSpeed: "2",
              cursor: "",
            }}
          />
        </div>
      </div>
    </div>
  ) : (
    <div></div>
  );
}

const stroies = [
  {
    url: "",
    content: ({ action, isPaused }) => {
      return <Advanced show={true} />;
    },
    duration: 6500,
  },
  {
    header: {
      heading: "Our First Few Dates",
      subheading: "NJ/NY, Nov 2021-Jan 2022",
    },
    url: "imgs/our-story/city-trip.jpeg",
    duration: 6500,

    styles: {
      color: "white",
    },
  },
  {
    header: {
      heading: "First Vacation Together",
      subheading: "Miami FL, Feb 2022",
    },
    url: "imgs/our-story/first-trip.mp4",
    duration: 6500,
    type: "video",
    styles: {
      fontFamily: fonts.NORMAL,
    },
  },
  {
    header: {
      heading: "More Trips Together",
      subheading: "TN/NV",
    },
    url: "imgs/our-story/more-trips.mp4",
    duration: 6500,
    type: "video",
    styles: {
      fontFamily: fonts.NORMAL,
    },
  },
  {
    header: {
      heading: "Even More!",
      subheading: "MA/CT",
    },
    url: "imgs/our-story/trips-together.mp4",
    duration: 6500,
    type: "video",
    styles: {
      fontFamily: fonts.NORMAL,
    },
  },
  {
    header: {
      heading: "Friends",
    },
    url: "imgs/our-story/friends.mp4",
    duration: 6500,
    type: "video",
    styles: {
      fontFamily: fonts.NORMAL,
    },
  },
  {
    header: {
      heading: "The Moment",
      subheading: "HighLine NY, September 30, 2023",
    },
    url: "imgs/our-story/engaged.mp4",
    duration: 6500,
    type: "video",
    styles: {
      fontFamily: fonts.NORMAL,
    },
  },
  {
    header: {
      heading: "To Be Continued",
      subheading: "Philidelphia PA, April 21, 2024",
    },
    url: "imgs/our-story/tbc.jpg",
    duration: 6500,
    styles: {
      fontFamily: fonts.NORMAL,
    },
  },
];

function Story() {
  const navigate = useNavigate();
  useEffect(() => {
    disableBodyScroll(document.body);
  }, []);
  return (
    <div className="story_time">
      <Stories
        keyboardNavigation
        defaultInterval={3000}
        stories={stroies.map((el) => {
          return {
            ...el,
            styles: {
              position: "fixed",
              top: "0",
              justifyContent: "center",
              display: "flex",
            },
          };
        })}
        onStoryEnd={(s, st) => console.log("story ended", s, st)}
        onAllStoriesEnd={(s, st) => {
          enableBodyScroll(document.body);
          navigate(routes.HOME);
        }}
        onStoryStart={(s, st) => console.log("story started", s, st)}
        width={"100vw"}
        height={"100vh"}
      />
    </div>
  );
}

export default Story;
