import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import colors from "../Base/colors";
import axios from "axios";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import MenuIcon from "@mui/icons-material/Menu";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import { BASE_API_URL } from "../Base/api";
import fonts from "../Base/fonts";
const Noop = () => {};
const theme = createTheme({
  palette: {
    primary: { main: colors.PRIMARY },
    secondary: { main: colors.ALT },
    text: { main: "#FFFFFF" },
  },
});
const steps = [
  {
    label: "Choose Party",
    content: ({
      guestList = [],
      selectedGuestName = "",
      selectedGuestObject = {},
      onSelected = Noop,
    }) => {
      return (
        <div style={{ paddingTop: "15px" }}>
          <Typography fontFamily={fonts.NORMAL}>
            Note : The party name refers to how the envelope was addressed.{" "}
            <br></br>
            Also, this only needs to be filled out by 1 person.
          </Typography>

          <Select
            sx={{ marginTop: "20px" }}
            value={selectedGuestName}
            fullWidth
            onChange={(ev) => {
              onSelected(
                guestList.filter((el) => el.label == ev.target.value).pop()
              );
            }}
          >
            {guestList
              .sort((a, b) => {
                return a.label > b.label ? 1 : b.label > a.label ? -1 : 0;
              })
              .map((el) => {
                return (
                  <MenuItem
                    value={el.label}
                    selected={selectedGuestName == el.label}
                  >
                    {el.label}
                  </MenuItem>
                );
              })}
          </Select>
        </div>
      );
    },
  },
  {
    label: "Who's Coming",
    content: ({
      selectedGuestObject = { guest_list: [], rsvp_statuses: {} },
      onGuestRsvp = Noop,
    }) => {
      return (
        <div style={{ width: "100%", paddingTop: "15px" }}>
          <Typography fontFamily={fonts.NORMAL}>
            {" "}
            Check the people that are coming and press finish{" "}
          </Typography>
          <FormGroup>
            {selectedGuestObject.guest_list.map((el) => {
              return (
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={{ fontFamily: fonts.NORMAL }}
                      defaultChecked={
                        selectedGuestObject.rsvp_statuses &&
                        selectedGuestObject.rsvp_statuses[el] &&
                        selectedGuestObject.rsvp_statuses[el] == "coming"
                      }
                      onChange={(ev) => {
                        onGuestRsvp({
                          [el]: ev.target.checked ? "coming" : "not_coming",
                        });
                      }}
                    />
                  }
                  sx={{ fontFamily: fonts.NORMAL }}
                  label={
                    <Typography fontFamily={fonts.NORMAL}>{el}</Typography>
                  }
                />
              );
            })}
          </FormGroup>
        </div>
      );
    },
  },
];

export default function Rsvp() {
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const [guestList, setGuestList] = React.useState([]);
  const [guestSelected, setGuestSelected] = React.useState({});
  const [guestRsvpStatuses, setGuestRsvpStatuses] = React.useState({});
  const isStepOptional = (step) => {
    return step === 2;
  };

  React.useEffect(() => {
    axios.get(`${BASE_API_URL}/guests`).then((data) => {
      setGuestList(data.data.res);
      const guestList = data.data.res ? data.data.res : [];
      const selectedParty = window.localStorage.getItem("selected_party");
      const item = guestList.filter((el) => el.label == selectedParty).pop();
      console.log("item", item);
      if (selectedParty) {
        console.log("setting selected party", selectedParty);
        setGuestSelected(item);
        handleNext();
      } else {
        setGuestSelected({});
      }
    });
  }, []);

  React.useEffect(() => {
    if (guestSelected.guest_list) {
      let defaultState = {};

      if (guestSelected.rsvp_statuses) {
        console.log("guest", guestSelected);
        defaultState = guestSelected.rsvp_statuses;
      } else {
        guestSelected.guest_list.forEach((el) => {
          defaultState[el] = "not_coming";
        });
      }

      setGuestRsvpStatuses(defaultState);
    }
  }, [guestSelected]);

  console.log(guestList);

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);

    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const setPartyCache = (label) => {
    window.localStorage.setItem("selected_party", label);
  };

  console.log(guestRsvpStatuses);

  return (
    <ThemeProvider theme={theme}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          paddingTop: "10px",
        }}
      >
        <Box sx={{ width: "90%" }}>
          <Stepper activeStep={activeStep}>
            {steps.map((item, index) => {
              const stepProps = {};
              const labelProps = {};
              if (item.isOptional) {
                labelProps.optional = (
                  <Typography variant="caption">Optional</Typography>
                );
              }
              if (isStepSkipped(index)) {
                stepProps.completed = false;
              }
              return (
                <Step key={item.label} {...stepProps}>
                  <StepLabel
                    {...labelProps}
                    StepIconProps={{ sx: { fontFamily: fonts.NORMAL } }}
                  >
                    <Typography fontFamily={fonts.NORMAL}>
                      {item.label}
                    </Typography>
                  </StepLabel>
                </Step>
              );
            })}
          </Stepper>
          {activeStep === steps.length ? (
            <div
              style={{
                padding: "20px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Typography fontFamily={fonts.NORMAL}>
                Rsvp Confirmed! Feel free to exit or click on the menu{" "}
                <MenuIcon sx={{ color: colors.PRIMARY }} /> to explore more.
              </Typography>
            </div>
          ) : (
            <div
              style={{
                padding: "10px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {steps[activeStep].content({
                guestList: guestList,
                onSelected: (lbl) => {
                  console.log(lbl);
                  setGuestSelected(lbl);
                },
                selectedGuestName: guestSelected.label,
                selectedGuestObject: guestSelected,
                onGuestRsvp: (obj) => {
                  setGuestRsvpStatuses({
                    ...guestRsvpStatuses,
                    ...obj,
                  });
                },
              })}
              <div
                style={{
                  display: "flex",
                  flex: "1",
                  justifyContent: "space-between",
                  position: "fixed",
                  bottom: "20px",
                  width: "90%",
                }}
              >
                <Button
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={{ mr: 1 }}
                >
                  Back
                </Button>
                <Box sx={{ flex: "1 1 auto" }} />
                {isStepOptional(activeStep) && (
                  <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                    Skip
                  </Button>
                )}

                <Button
                  onClick={
                    !(activeStep === steps.length - 1)
                      ? handleNext
                      : () => {
                          axios
                            .post(`${BASE_API_URL}/guests/_rsvp`, {
                              user_id: guestSelected.id,
                              rsvp_statuses: guestRsvpStatuses,
                            })
                            .then((data) => {
                              setPartyCache(data.data.res.label);
                              handleNext();
                            });
                        }
                  }
                  disabled={guestSelected.id == null}
                >
                  {activeStep === steps.length - 1 ? "Finish" : "Next"}
                </Button>
              </div>
            </div>
          )}
        </Box>
      </div>
    </ThemeProvider>
  );
}
