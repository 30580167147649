import {
  Alert,
  Checkbox,
  Divider,
  Snackbar,
  Typography,
  duration,
} from "@mui/material";
import colors from "../Base/colors";
import fonts from "../Base/fonts";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";
import FastfoodIcon from "@mui/icons-material/DiningOutlined";
import LaptopMacIcon from "@mui/icons-material/LaptopMacOutlined";
import HotelIcon from "@mui/icons-material/Hotel";
import RepeatIcon from "@mui/icons-material/Repeat";
import CelebrationIcon from "@mui/icons-material/Celebration";
import DriveEtaIcon from "@mui/icons-material/DriveEta";
import DiningOutlined from "@mui/icons-material/DiningOutlined";
import { GiBigDiamondRing, GiDiamondRing } from "react-icons/gi/index";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import Typewriter from "typewriter-effect";
import { useState } from "react";
import DividerWithText from "../Component/DividerWithText";
import LocalBarIcon from "@mui/icons-material/LocalBar";

import { WEDDING_DATE } from "../Base/wedding_date";
import { useNavigate } from "react-router-dom";
import routes from "../Base/routes";

const getDaysRemaningForWedding = () => {
  const currentDate = new Date();
  const differenceInMilliseconds = WEDDING_DATE - currentDate;
  const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);
  const daysRemaining = Math.floor(differenceInDays);
  console.log(daysRemaining);
  return daysRemaining;
};

function Main() {
  const navigate = useNavigate();
  const DIVIDER_WIDTH = "50px";
  const daysRemaining = getDaysRemaningForWedding();
  const handleAddressClick = (streetAddress) => async () => {
    await copyTextToClipboard(streetAddress);
    setIsCopied(true);
  };
  const [isHelpViewable, setIsHelpViewable] = useState(true);
  const [isCopied, setIsCopied] = useState(false);

  async function copyTextToClipboard(text) {
    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand("copy", true, text);
    }
  }

  return (
    <div>
      <div style={{ width: "100vw" }}>
        <div
          style={{
            width: "100vw",
            display: "flex",
            justifyContent: "center",
            position: "relative",
          }}
        >
          <img src="imgs/main.jpg" style={{ maxWidth: "100%" }}></img>
        </div>

        <div
          class="caption"
          style={{
            color: "#fff" /* Text color of the caption */,
            paddingTop: "10px",
            textAlign: "center",
          }}
        >
          <Typography
            color={colors.PRIMARY}
            fontFamily={fonts.TITLE}
            fontWeight={"400"}
            sx={{ fontSize: "28px" }}
          >
            Our Wedding
          </Typography>
        </div>
      </div>

      <DividerWithText
        fontSize={"20px"}
        fontFamily={fonts.NORMAL}
        paragraphs={[
          "April 21, 2024",
          daysRemaining > 0
            ? [
                `${daysRemaining} days till `,
                <GiBigDiamondRing style={{ fontSize: "16px" }} />,
              ]
            : daysRemaining < 0
            ? [`We're Married!`]
            : [`It's Today!`],
        ]}
        fontWeight={800}
      />

      <Timeline position="alternate">
        <TimelineItem>
          <TimelineOppositeContent
            sx={{ m: "auto 0", fontFamily: fonts.NORMAL }}
            align="right"
            variant="h6"
            color="text.secondary"
          >
            4:30pm
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineConnector />
            <TimelineDot sx={{ color: colors.PRIMARY }} variant="outlined">
              <DriveEtaIcon />
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent sx={{ py: "12px", px: 2 }}>
            <Typography
              variant="h7"
              component="span"
              fontFamily={fonts.NORMAL}
              color={colors.PRIMARY}
            >
              Location
            </Typography>
            <Typography
              fontFamily={fonts.NORMAL}
              sx={{}}
              onClick={handleAddressClick(
                "640 N Broad St, Philadelphia, PA 19130"
              )}
             
            >
              Osteria
            </Typography>
            <Typography
              fontFamily={fonts.NORMAL}
              sx={{ textDecoration: "underline", color: colors.ALT }}
              onClick={handleAddressClick(
                "640 N Broad St, Philadelphia, PA 19130"
              )}
              
            >
              640 N Broad St, Philadelphia, PA 19130.
            </Typography>
            
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineOppositeContent
            sx={{ m: "auto 0", fontFamily: fonts.NORMAL }}
            variant="h6"
            color="text.secondary"
          >
            4:45pm
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineConnector />
            <TimelineDot sx={{ color: colors.PRIMARY }} variant="outlined">
              <GiDiamondRing size={"20px"} />
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent sx={{ py: "12px", px: 2 }}>
            <Typography
              variant="h7"
              component="span"
              fontFamily={fonts.NORMAL}
              color={colors.PRIMARY}
            >
              Ceremony
            </Typography>
            <Typography fontFamily={fonts.NORMAL}>
              Wedding Ceremony will be live-streamed as well
            </Typography>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineOppositeContent
            sx={{ m: "auto 0", fontFamily: fonts.NORMAL }}
            variant="h6"
            color="text.secondary"
          >
            5:00pm
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineConnector />
            <TimelineDot sx={{ color: colors.PRIMARY }} variant="outlined">
              <LocalBarIcon size={"20px"} />
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent sx={{ py: "12px", px: 2 }}>
            <Typography
              variant="h7"
              component="span"
              fontFamily={fonts.NORMAL}
              color={colors.PRIMARY}
            >
              Cocktail Hour
            </Typography>
            <Typography fontFamily={fonts.NORMAL}>
              Drinks, first dances, and more dancing
            </Typography>
          </TimelineContent>
        </TimelineItem>

        <TimelineItem>
          <TimelineOppositeContent
            sx={{ m: "auto 0", fontFamily: fonts.NORMAL }}
            variant="h6"
            color="text.secondary"
          >
            6:00-8:00pm
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineConnector />
            <TimelineDot sx={{ color: colors.PRIMARY }} variant="outlined">
              <DiningOutlined />
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent sx={{ py: "12px", px: 2 }}>
            <Typography
              variant="h7"
              component="span"
              fontFamily={fonts.NORMAL}
              color={colors.PRIMARY}
            >
              Dinner
            </Typography>
            <Typography fontFamily={fonts.NORMAL}>
              Seated dinner, speeches,and mingling
            </Typography>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineOppositeContent
            sx={{ m: "auto 0", fontFamily: fonts.NORMAL }}
            variant="h6"
            color="text.secondary"
          >
            8:00-9pm
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineConnector sx={{}} />
            <TimelineDot sx={{ color: colors.PRIMARY }} variant="outlined">
              <CelebrationIcon />
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>

          <TimelineContent sx={{ py: "12px", px: 2 }}>
            <Typography
              variant="h7"
              component="span"
              fontFamily={fonts.NORMAL}
              color={colors.PRIMARY}
            >
              Celebration
            </Typography>
            <Typography fontFamily={fonts.NORMAL}>
              Let's get the party started.{" "}
              <br></br>
              <br></br>
              <a
                onClick={() => {
                  navigate(routes.PLAYLISTS);
                }}
                style={{textDecoration:"underline",color:colors.ALT}}
              >
                Click here to add a song suggestion
              </a>
            </Typography>
          </TimelineContent>
        </TimelineItem>
      </Timeline>
      <br></br>
      <br></br>
      <br></br>

      <Divider
        sx={{
          margin: "0 auto", // Center the Divider horizontally
          textAlign: "center",
          width: DIVIDER_WIDTH,
        }}
      ></Divider>

      <Snackbar
        open={isCopied}
        onClose={() => {
          setIsCopied(false);
        }}
        variant="success"
        autoHideDuration={3 * 1000}
      >
        <Alert
          variant="filled"
          onClose={() => {
            setIsCopied(false);
          }}
          severity="info"
          sx={{ width: "100%", backgroundColor: colors.ALT }}
        >
          Copied address to clipboard!
        </Alert>
      </Snackbar>
    </div>
  );
}

export default Main;
