import React from 'react';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

const DividerWithText = ({ paragraphs = [] ,dividerWith,fontFamily,fontSize,fontWeight}) => {
  
       
  return (
    <div style={{width:"100%",display:"flex",justifyContent:"center"}}>
    <div style={{ display: 'flex', alignItems: 'center',width:"200px" }}>
       <Divider style={{ flex: 1 ,paddingTop:"20px",borderBottomWidth:"2px"}} />
       <Typography  fontFamily={fontFamily} fontWeight={fontWeight}sx={{fontSize:fontSize,paddingTop:"10px",textAlign:"center",alignItems:"center"}} color="textSecondary" style={{ margin: '0 16px' }}>
                {paragraphs.map((el)=> {
                    if (el instanceof Array) {
                        return(<React.Fragment>
                            {el}
                        </React.Fragment>)
                    }
                    return (<React.Fragment>
                        {el}
                        <br/>
                    </React.Fragment>)
                })}
        </Typography>
     
      <Divider style={{ flex: 1 ,paddingTop:"20px",borderBottomWidth:"2px"}} />
    </div>
    </div>
  );
};

export default DividerWithText;
