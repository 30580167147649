import * as React from 'react';
import ReactPhotoCollage from '../Component/react-photo-collage/ReactPhotoCollageContainer';
import { pics, pics_thumbs } from '../Base/engagement_pics';

function generateRandomNumbers(targetSum) {
    console.time('time');
    let result = [];
    let options = [2,3]
    let ctr = 0;
    // Loop until the target sum is reached
    while (targetSum > 0) {
        let num = options[ctr%options.length]
        result.push(num);
        targetSum -= num;
        ctr ++;
    }
    console.timeEnd('time');
    return result;
}

const setting = {
    width: '100%',
    height: ['350px', '170px'],
    layout: [...generateRandomNumbers(pics_thumbs.length)],
    photos: pics_thumbs,
    highQualityPhotos: pics.map((el) => {
        return {
            src: el.source,
        };
    }),
    showNumOfRemainingPhotos: true,
};

export default function App() {
    return <ReactPhotoCollage {...setting} />;
}
