import React, { useEffect, useState, useCallback, useMemo } from 'react';

import { ReactPhotoCollageComponent } from './ReactPhotoCollageComponent';
import Lightbox from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css';
import 'yet-another-react-lightbox/plugins/counter.css';
import Counter from 'yet-another-react-lightbox/plugins/counter';
import Share from 'yet-another-react-lightbox/plugins/share';
import Zoom from 'yet-another-react-lightbox/plugins/zoom';

import Download from 'yet-another-react-lightbox/plugins/download';

const createPhotoIds = (photos) => {
    return photos.map((data, i) => {
        return { ...data, id: i };
    });
};
const createLayoutPhotoMaps = (layout, photos) => {
    const newPhotos = createPhotoIds(photos);
    const newMaps = {};
    layout.reduce((accumulator, currentValue, currentIndex) => {
        newMaps[currentIndex] = newPhotos.slice(
            accumulator,
            accumulator + currentValue
        );
        return accumulator + currentValue;
    }, 0);

    return newMaps;
};

const checkProps = (props) => {
    const defaultProps = {
        width: '800px',
        height: new Array(props.layout.length),
        layout: [],
        photos: [],
        showNumOfRemainingPhotos: false,
    };

    const newProps = { ...defaultProps, ...props };
    if (newProps.height.length < newProps.layout.length) {
        for (let i = 0; i < newProps.layout.length; i++) {
            newProps.height[i] = '200px';
        }
    }
    return newProps;
};
const ReactPhotoCollageContainer = (props) => {
    const currProps = useMemo(() => checkProps(props), [props]);

    const {
        width,
        height,
        layout,
        photos,
        showNumOfRemainingPhotos,
        highQualityPhotos: hqPhotos,
    } = currProps;
    const layoutNum = layout.reduce(
        (accumulator, currentValue) => accumulator + currentValue,
        0
    );
    const remainingNum = photos.length - layoutNum;
    const [allowRender, setAllowRender] = useState(false);
    const [layoutPhotoMaps, setLayoutPhotoMaps] = useState({});
    const [viewerIsOpen, setViewerIsOpen] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0);
    const [currentImage, setCurrentImage] = useState(0);

    useEffect(() => {
        setLayoutPhotoMaps(createLayoutPhotoMaps(layout, photos));
    }, []);
    useEffect(() => {
        Object.keys(layoutPhotoMaps).length
            ? setAllowRender(true)
            : setAllowRender(false);
    }, [layoutPhotoMaps]);

    const openLightbox = useCallback((id) => {
        console.log(id);
        setCurrentImage(parseInt(id));
        setViewerIsOpen(true);
    }, []);
    const closeLightbox = useCallback(() => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    }, []);
    const zoomRef = React.useRef(null);
    console.log(
        hqPhotos[currentImage],
        currentImage,
        (currentImage + 1) % hqPhotos.length,
        (currentImage + hqPhotos.length - 1) % hqPhotos.length
    );
    if (allowRender) {
        return (
            <div>
                <ReactPhotoCollageComponent
                    width={width}
                    height={height}
                    layout={layout}
                    layoutPhotoMaps={layoutPhotoMaps}
                    layoutNum={layoutNum}
                    remainingNum={remainingNum}
                    showNumOfRemainingPhotos={showNumOfRemainingPhotos}
                    openLightbox={openLightbox}
                />
                {viewerIsOpen ? (
                    <Lightbox
                        open={viewerIsOpen}
                        index={currentImage}
                        close={() => closeLightbox()}
                        slides={hqPhotos}
                        plugins={[Counter, Download, Share, Zoom]}
                    />
                ) : (
                    ''
                )}
            </div>
        );
    }

    return null;
};

export default ReactPhotoCollageContainer;
