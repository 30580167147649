import ListItemContent from "@mui/joy/ListItemContent";
import {
  Button,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
  Snackbar,
  Alert,
  IconButton,
  Modal,
  Box,
  Slide,
  Paper,
  Tab,
  Tabs,
} from "@mui/material";
import * as React from "react";
import fonts from "../Base/fonts";
import colors from "../Base/colors";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import ReactJkMusicPlayer from "react-jinke-music-player";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import "react-jinke-music-player/assets/index.css";
import axios from "axios";
import { useSwipeable } from "react-swipeable";
import { BASE_API_URL } from "../Base/api";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SearchBar from "../Component/SearchBar";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
const url =
  "https://raw.githubusercontent.com/the-maazu/react-material-music-player/master/sample_media/bach.jpg";

const Noop = () => {};

function PlayListItem({
  imageUrl = "",
  Title = "",
  Authors = [],
  isPlaying = false,
  onClick = Noop,
  index = -1,
  songHasPreview,
  isFunctional = false,
  withAdd = false,
  inListAlready,
  songPreview,
  trackUri,
  onAddItemClick = Noop,
}) {
  const color = isPlaying ? colors.ALT : colors.PRIMARY;
  console.log(inListAlready);
  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <Button
        variant={""}
        sx={{ width: isFunctional ? "80%" : "100%", color: colors.ALT }}
        onClick={() => {
          onClick(index);
        }}
        disabled={!songHasPreview}
      >
        <ListItem sx={{ padding: "5px" }}>
          <ListItemAvatar
            sx={
              !isFunctional
                ? { width: "48px", height: "48px" }
                : {
                    width: "48px",
                    height: "48px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }
            }
          >
            {!isFunctional || imageUrl != "" ? (
              <img
                style={{ width: "inherit", height: "inherit" }}
                src={imageUrl}
              ></img>
            ) : (
              <AddIcon sx={{ fontSize: "2rem", color: colors.PRIMARY }} />
            )}
          </ListItemAvatar>
          <ListItemContent
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <div style={{ width: "100%" }}>
              <ListItemText>
                <Typography
                  fontFamily={fonts.NORMAL}
                  fontSize={"0.9rem"}
                  fontWeight={800}
                  sx={{
                    textTransform: "capitalize",
                    color: color,
                  }}
                >
                  {Title}
                </Typography>
              </ListItemText>
              <ListItemText>
                <Typography
                  fontFamily={fonts.NORMAL}
                  fontWeight={"200"}
                  fontSize={"0.75rem"}
                  sx={{ textTransform: "capitalize" }}
                  color={colors.TEXT}
                >
                  {Authors.join(" & ")}
                </Typography>
                <Typography
                  fontFamily={fonts.NORMAL}
                  fontWeight={"200"}
                  fontSize={"0.65rem"}
                  sx={{ textTransform: "capitalize" }}
                  color={"red"}
                >
                  {songHasPreview ? "" : "not playable"}
                </Typography>
              </ListItemText>
            </div>
          </ListItemContent>
        </ListItem>
      </Button>
      {withAdd ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "20%",
          }}
        >
          {!inListAlready ? (
            <IconButton
              onClick={() =>
                onAddItemClick(
                  {
                    Title,
                    Authors,
                    imageUrl,
                    songPreview,
                    uri: trackUri,
                  },
                  trackUri
                )
              }
            >
              <AddCircleOutlineIcon
                sx={{ color: colors.PRIMARY, fontSize: "1.5rem" }}
              />
            </IconButton>
          ) : (
            <CheckCircleOutlineIcon
              sx={{ color: colors.PRIMARY, fontSize: "1.5rem" }}
            />
          )}
        </div>
      ) : undefined}
    </div>
  );
}

const toJsMusicPlayer = (list) => {
  return list.map((el) => {
    return {
      name: el.Title,
      singer: el.Authors.join(" & "),
      cover: el.imageUrl,
      musicSrc: el.songPreview ? el.songPreview : "./audio/dummy.mp3",
    };
  });
};

const RequestSongModal = ({
  isOpen = false,
  onClose = Noop,
  resultsOverride,
  suggestionList,
  itemAddedToBackend = Noop,
}) => {
  const [results, setResults] = React.useState([]);
  const audi = new Audio("");
  audi.onended = () => {
    setIsPlayIndex(-100);
  };
  const [audioItem, setAudioItem] = React.useState(audi);
  const [playIndex, setIsPlayIndex] = React.useState(-100);
  React.useEffect(() => {
    console.log("pasuing...");
    pauseSong();
  }, [isOpen]);
  return (
    <Modal
      open={isOpen}
      onClose={() => {
        console.log("closing");
        audioItem.pause();
        audioItem.src = "";
        setAudioItem(audioItem);
        onClose();
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ width: "100vw", height: "100vh", zIndex: "21474836454" }}
      disableAutoFocus={true}
    >
      <Slide
        in={isOpen}
        direction="up"
        easing={{
          exit: "linear",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            overflow: "scroll",
            width: "100%",
            height: "100vh",
            bgcolor: "background.paper",
            boxShadow: 24,
          }}
        >
          <IconButton
            onClick={() => {
              console.log("closing");
              audioItem.pause();
              audioItem.src = "";
              setAudioItem(audioItem);
              onClose();
            }}
            size="large"
            sx={{
              paddingLeft: "5px",
              zIndex: "999900000",
              color: colors.PRIMARY,
            }}
          >
            {" "}
            <ArrowBackIcon fontSize="20px" />
          </IconButton>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {" "}
            <div
              style={{
                width: "85%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <SearchBar
                onSearch={(searchTerm) => {
                  audioItem.pause();
                  audioItem.src = "";
                  setAudioItem(audioItem);
                  setIsPlayIndex(-100);
                  axios
                    .get(
                      `${BASE_API_URL}/search-spotify?q=${encodeURIComponent(
                        searchTerm
                      )}`
                    )
                    .then((res) => {
                      setResults(res.data.res);
                    });
                }}
              ></SearchBar>
            </div>
            <div
              style={{
                width: "90%",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                paddingBottom: "40px",
              }}
            >
              {results.map(
                ({ imageUrl, Title, Authors, songPreview, uri }, index) => (
                  <PlayListItem
                    Authors={Authors}
                    Title={Title}
                    imageUrl={imageUrl}
                    isPlaying={playIndex == index}
                    index={index}
                    songPreview={songPreview}
                    trackUri={uri}
                    inListAlready={
                      suggestionList.filter((el) => {
                        return el.track_uri == uri;
                      }).length > 0
                    }
                    onAddItemClick={(track_info, uri) => {
                      axios
                        .post(`${BASE_API_URL}/playlists/_add_songs`, {
                          uri,
                          track_info: track_info,
                        })
                        .then(() => {
                          itemAddedToBackend();
                        });
                    }}
                    onClick={() => {
                      setIsPlayIndex(index);

                      if (!songPreview) {
                        return;
                      }
                      if (audioItem.paused && audioItem.src == songPreview) {
                        audioItem.play();
                      } else if (
                        !audioItem.paused &&
                        audioItem.src == songPreview
                      ) {
                        audioItem.pause();
                      } else {
                        audioItem.src = songPreview;
                        setAudioItem(audioItem);
                        audioItem.play();
                      }
                    }}
                    songHasPreview={!!songPreview}
                    isFunctional={true}
                    withAdd={true}
                  ></PlayListItem>
                )
              )}
            </div>
          </div>
        </Box>
      </Slide>
    </Modal>
  );
};

const pauseSong = () => {
  Array.from(document.getElementsByClassName("group play-btn")).forEach(
    (el) => {
      console.log("stat", el.title != "Click to play");
      if (el.title != "Click to play") {
        el.click();
      }
    }
  );
};

export default function PlayList() {
  const firstRender = React.useMemo(() => console.log("first Render"), []);
  const [highlightedItemIdx, setHighlightedItemIdx] = React.useState(-1);
  const [isExpand, setIsExpand] = React.useState(false);
  const [plist, setPlist] = React.useState([]);
  const [audioList, setAudioList] = React.useState([]);
  const [suggestedPlist, setSuggestedPlist] = React.useState([]);
  const [coverImg, setCoverImg] = React.useState("./imgs/play_list_cover.jpg");
  const [isAddSongOpen, setIsAddSongOpen] = React.useState(false);
  console.log("itme", highlightedItemIdx);
  React.useEffect(() => {
    setAudioList([]);
    setPlist([]);
    Promise.all([
      axios.get(`${BASE_API_URL}/playlist`).then((el) => {
        setCoverImg(el.data.res.coverImageUrl);
      }),
      axios.get(`${BASE_API_URL}/playlist/suggestions`).then((el) => {
        setSuggestedPlist(el.data.res);
        setPlist(el.data.res.map((el) => el.track_info));
        setAudioList(toJsMusicPlayer(el.data.res.map((el) => el.track_info)));
      }),
    ]).then(() => {
      console.log("after");
      setHighlightedItemIdx(-100);
    });
  }, [isAddSongOpen]);
  const { ref } = useSwipeable({
    onSwipedDown: (evData) => {
      console.log("swipping down");
      if (isExpand) {
        setIsExpand(false);
      }
    },
    onSwipedLeft: () => {
      if (isExpand) {
        const next = (highlightedItemIdx + 1) % plist.length;
        console.log(next);
        setHighlightedItemIdx(next);
      }
    },
  });
  const { ref: refPlayer } = useSwipeable({
    onSwipedUp: () => {
      setIsExpand(true);
    },
  });
  React.useEffect(() => {
    const el = document.querySelector(".music-player-panel");
    if (el) {
      refPlayer(el);
      return () => refPlayer({});
    }
  }, [isExpand]);
  React.useEffect(() => {
    Array.from(
      document.getElementsByClassName(
        "react-jinke-music-player-mobile-operation group"
      )
    ).forEach((el) => el.remove());
    if (isExpand) {
      const el = document.getElementsByClassName("items");
      if (el && el.item(0)) {
        console.log(
          el.item(0).children[0].addEventListener("click", () => {
            setIsExpand(false);
          })
        );
      }
    } else {
      const el = document.getElementsByClassName("audio-lists-btn");
      if (el && el.item(0)) {
        console.log(
          el.item(0).addEventListener("click", () => {
            setIsExpand(true);
          })
        );
      }
    }
    ref(document);
    return () => ref({});
  }, [isExpand]);
  //audio-lists-btn"
  const onItemClick = (index) => {
    console.log(index);
    Array.from(document.getElementsByClassName("group play-btn")).forEach(
      (el) => el.click()
    );

    setHighlightedItemIdx(index);
  };
  return (
    <React.Fragment>
      <RequestSongModal
        isOpen={isAddSongOpen}
        suggestionList={suggestedPlist}
        onClose={() => {
          setIsAddSongOpen(false);
        }}
        resultsOverride={plist}
        itemAddedToBackend={() => {
          axios.get(`${BASE_API_URL}/playlist/suggestions`).then((el) => {
            setSuggestedPlist(el.data.res);
          });
        }}
      />
      {isExpand ? (
        <IconButton
          onClick={() => {
            setIsExpand(false);
          }}
          size="large"
          sx={{
            position: "fixed",
            top: "2%",
            left: "1%",
            zIndex: "999900000",
            color: colors.PRIMARY,
          }}
        >
          {" "}
          <ArrowBackIcon fontSize="20px" />
        </IconButton>
      ) : undefined}
      <ReactJkMusicPlayer
        mode="full"
        showDestroy={false}
        showReload={false}
        showLyric={false}
        showMiniModeCover={false}
        onCoverClick={() => {
          setIsExpand(true);
        }}
        showMediaSession={false}
        drag={false}
        glassBg={false}
        className={isExpand ? "music-panel-expand" : ""}
        remove={false}
        defaultPosition={{ bottom: 0 }}
        quietUpdate={false}
        autoHiddenCover={false}
        showPlayMode={false}
        toggleMode={false}
        playIndex={highlightedItemIdx}
        autoPlayInitLoadPlayList={false}
        onAudioListsChange={() => {}}
        showPlay={true}
        once={false}
        autoPlay={false}
        clearPriorAudioLists
        playMode="order"
        showThemeSwitch={false}
        onAudioPlay={() => {
          console.log("playing song");
          if (isAddSongOpen || highlightedItemIdx < 0 || firstRender) {
            pauseSong();
          }
        }}
        onPlayIndexChange={(inf) => {
          if (inf === 0) {
          } else {
            console.log("index has changed to ", inf);
            setHighlightedItemIdx(inf);
          }
        }}
        showDownload={false}
        theme="light"
        responsive={isExpand}
        audioLists={audioList}
        showMiniProcessBar={true}
        style={{ width: "100%", position: "sticky", bottom: "0" }}
      />{" "}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          width: "100%",
          paddingBottom: "70px",
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            paddingBottom: "10px",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {" "}
          <img src={coverImg} width={"50%"} />
        </div>

        <div style={{ paddingBottom: "15px" }}>
          <PlayListItem
            Title={"Request Song"}
            imageUrl={""}
            isPlaying={false}
            index={-100}
            songHasPreview={true}
            isFunctional={true}
            onClick={() => {
              setIsAddSongOpen(true);
            }}
          ></PlayListItem>
        </div>

        {plist.map(({ imageUrl, Title, Authors, songPreview }, index) => (
          <PlayListItem
            Authors={Authors}
            Title={Title}
            imageUrl={imageUrl}
            isPlaying={highlightedItemIdx == index}
            index={index}
            onClick={onItemClick}
            songHasPreview={!!songPreview}
          ></PlayListItem>
        ))}
      </div>
    </React.Fragment>
  );
}
