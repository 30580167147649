export default {
  HOME: "/home",
  PHOTOS: "/photos",
  OUT_STORY: "/our-story",
  PLAYLISTS: "/playlists",
  STREAM: "/stream",
  FAQ: "/faq",
  RSVP: "/rsvp",
  ACCOMODATIONS: "/accommodations",
  REGISTRY: "/registry",
};
