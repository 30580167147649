import React, { useState } from "react";
import { styled, alpha } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";

//drawer elements used
import Drawer from "@mui/material/Drawer";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Divider from "@mui/material/Divider";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import FolderIcon from "@mui/icons-material/Folder";
import ImageIcon from "@mui/icons-material/Image";
import DescriptionIcon from "@mui/icons-material/Description";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import Button from "@mui/material/Button";
import colors from "../Base/colors";
import fonts from "../Base/fonts";
import { SwipeableDrawer } from "@mui/material";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import routes from "../Base/routes";

import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import FavoriteIcon from "@mui/icons-material/Favorite";
import NavigationIcon from "@mui/icons-material/Navigation";

const StyledSearch = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.primary.main, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.primary.main, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const listItemSharedStyle = {};

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

//search as JSX
const search = (
  <StyledSearch>
    <SearchIconWrapper>
      <SearchIcon />
    </SearchIconWrapper>
    <StyledInputBase
      placeholder="Suchen…"
      inputProps={{ "aria-label": "search" }}
    />
  </StyledSearch>
);

const NoOp = () => {};

const resolveTitleFromPage = () => {
  const p = window.location.hash.split("#").join("");
  switch (p) {
    case routes.PHOTOS:
      return "Photos";
    case routes.PLAYLISTS:
      return "Playlist";
    case routes.STREAM:
      return "Stream";
    case routes.FAQ:
      return "Faq";
    case routes.RSVP:
      return "Rsvp";
    default:
      return "Carelys & Ahmad";
  }
};

const isRouteIn = (routes = []) => {
  const p = window.location.hash.split("#").join("");
  console.log(p,routes)
  return routes.includes(p);
};

export default function MainNavigation({ onClick = NoOp }) {
  const onItemClick = (route) => {
    return () => {
      onClick(route);
      setState(false);
    };
  };
  /*
  react useState hook to save the current open/close state of the drawer,
  normally variables dissapear afte the function was executed
  */
  const [open, setState] = useState(false);

  /*
  function that is being called every time the drawer should open or close,
  the keys tab and shift are excluded so the user can focus between
  the elements with the keys
  */
  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    //changes the function state according to the value of open
    setState(open);
  };

  return (
    <React.Fragment>
      {!isRouteIn([routes.OUT_STORY,routes.PLAYLISTS,routes.RSVP])?<Fab
        aria-label="add"
        style={{
          position: "fixed",
          bottom: "15px",
          right: "15px",
          background: "white",
        }}
        onClick={toggleDrawer(true)}
      >
        <MenuIcon sx={{ color: colors.PRIMARY }} />
      </Fab>:undefined}
      

      <AppBar
        position="static"
        sx={{
          backgroundColor: "white",
          boxShadow: "none",
          height: "56px",
          position: "sticky",
          top: "0",
          zIndex: "1000",
        }}
      >
        <Container
          maxWidth="lg"
          disableGutters="true"
          style={{ position: "sticky" }}
        >
          <Toolbar
            sx={{
              display: "flex",
              justifyContent: "space-between",
              position: "sticky",
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer(true)}
              sx={{
                mr: 2,
                display: {
                  xs: "block",
                  sm: "none",
                },
              }}
            >
              <MenuIcon style={{ color: colors.PRIMARY, fontSize: "32px" }} />
            </IconButton>
            <Typography
              variant="h7"
              sx={{ fontFamily: fonts.TITLE, fontWeight: 1000 }}
              color={colors.PRIMARY}
            >
              {resolveTitleFromPage()}
            </Typography>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              sx={{
                mr: 2,
                display: {
                  xs: "block",
                  sm: "none",
                },
              }}
            >
              <MenuIcon style={{ color: "white", fontSize: "32px" }} />
            </IconButton>

            {/* The outside of the drawer */}
            <SwipeableDrawer
              disableSwipeToOpen={false}
              transitionDuration={500}
              //from which side the drawer slides in
              anchor="left"
              //if open is true --> drawer is shown
              open={open}
              //function that is called when the drawer should close
              onClose={toggleDrawer(false)}
              //function that is called when the drawer should open
              onOpen={toggleDrawer(true)}
              sx={{
                minHeight: "95vh",
                "& .MuiBackdrop-root": {
                  display: "none",
                  width: "100vw",
                },
                zIndex: "90000000",
              }}
            >
              {/* The inside of the drawer */}
              <Box
                sx={{
                  p: 1,
                  height: 1,
                  minHeight: "98.1vh",
                }}
              >
                {/* 
                  when clicking the icon it calls the function toggleDrawer 
                  and closes the drawer by setting the variable open to false
                  */}
                <IconButton sx={{ mb: 2 }}>
                  <ArrowBackIcon
                    style={{ color: colors.PRIMARY, fontSize: "32px" }}
                    onClick={toggleDrawer(false)}
                  />
                </IconButton>

                <Box sx={{ mb: 2 }}>
                  <ListItemButton onClick={onItemClick(routes.HOME)}>
                    <ListItemText
                      sx={{
                        textAlign: "center",
                        "&:hover": {
                          color: "#5882BB",
                        },
                        color: colors.PRIMARY,
                      }}
                      primary="Home"
                      primaryTypographyProps={{
                        fontFamily: fonts.NORMAL,
                        fontSize: "1.5rem",
                      }}
                    />
                  </ListItemButton>

                  <ListItemButton onClick={onItemClick(routes.PHOTOS)}>
                    <ListItemText
                      sx={{
                        textAlign: "center",
                        "&:hover": {
                          color: "#5882BB",
                        },
                        color: colors.PRIMARY,
                      }}
                      primaryTypographyProps={{
                        fontFamily: fonts.NORMAL,
                        fontSize: "1.5rem",
                      }}
                      primary="Photos"
                    />
                  </ListItemButton>
                  
                  <ListItemButton onClick={onItemClick(routes.OUT_STORY)}>
                    <ListItemText
                      sx={{
                        textAlign: "center",
                        "&:hover": {
                          color: "#5882BB",
                        },
                        color: colors.PRIMARY,
                      }}
                      primaryTypographyProps={{
                        fontFamily: fonts.NORMAL,
                        fontSize: "1.5rem",
                      }}
                      primary="Our Story"
                    />
                  </ListItemButton>

                  <ListItemButton onClick={onItemClick(routes.PLAYLISTS)}>
                    <ListItemText
                      sx={{
                        textAlign: "center",
                        color: colors.PRIMARY,
                        "&:hover": {
                          color: "#5882BB",
                        },
                      }}
                      primaryTypographyProps={{
                        fontFamily: fonts.NORMAL,

                        fontSize: "1.5rem",
                      }}
                      primary="Playlist"
                    />
                  </ListItemButton>

                  <ListItemButton onClick={onItemClick(routes.STREAM)}>
                    <ListItemText
                      sx={{
                        textAlign: "center",
                        color: colors.PRIMARY,
                        "&:hover": {
                          color: "#5882BB",
                        },
                      }}
                      primaryTypographyProps={{
                        fontFamily: fonts.NORMAL,

                        fontSize: "1.5rem",
                      }}
                      primary="Stream"
                    />
                  </ListItemButton>
                  <ListItemButton onClick={onItemClick(routes.FAQ)}>
                    <ListItemText
                      sx={{
                        textAlign: "center",
                        color: colors.PRIMARY,
                        "&:hover": {
                          color: "#5882BB",
                        },
                      }}
                      primaryTypographyProps={{
                        fontFamily: fonts.NORMAL,

                        fontSize: "1.5rem",
                      }}
                      primary="FAQ"
                    />
                  </ListItemButton>
                  <ListItemButton onClick={onItemClick(routes.RSVP)}>
                    <ListItemText
                      sx={{
                        textAlign: "center",
                        color: colors.PRIMARY,
                        "&:hover": {
                          color: "#5882BB",
                        },
                      }}
                      primaryTypographyProps={{
                        fontFamily: fonts.NORMAL,

                        fontSize: "1.5rem",
                      }}
                      primary="RSVP"
                    />
                  </ListItemButton>
                  <ListItemButton onClick={()=>{
                    window.open("https://www.marriott.com/events/start.mi?id=1701199260773&key=GRP", '_blank').focus();
                  }}>
                    <ListItemText
                      sx={{
                        textAlign: "center",
                        "&:hover": {
                          color: "#5882BB",
                        },
                        color: colors.PRIMARY,
                      }}
                      primaryTypographyProps={{
                        fontFamily: fonts.NORMAL,
                        fontSize: "1.5rem",
                      }}
                      primary="Hotel"
                    />
                  </ListItemButton>
                  <ListItemButton onClick={()=>{
                    window.open("https://www.honeyfund.com/site/baderkhan-andino-04-21-2024", '_blank').focus();
                  }}>
                    <ListItemText
                      sx={{
                        textAlign: "center",
                        "&:hover": {
                          color: "#5882BB",
                        },
                        color: colors.PRIMARY,
                      }}
                      primaryTypographyProps={{
                        fontFamily: fonts.NORMAL,
                        fontSize: "1.5rem",
                      }}
                      primary="Registry/Gifts"
                    />
                  </ListItemButton>
                </Box>

                

                <div
                  style={{
                    position: "absolute",
                    bottom: 20,
                    paddingLeft: "15px",
                    textAlign: "center",
                    display: "Flex",
                  }}
                >
                  <Typography fontFamily={fonts.NORMAL} textAlign={"center"}>
                    Created with{" "}
                  </Typography>
                  <FavoriteBorderIcon
                    sx={{
                      color: colors.PRIMARY,
                      paddingLeft: "5px",
                      paddingRight: "5px",
                    }}
                  />
                  <Typography fontFamily={fonts.NORMAL} textAlign={"center"}>
                    {" "}
                    By Ahmad Baderkhan
                  </Typography>
                </div>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    position: "absolute",
                    bottom: "0",
                    left: "50%",
                    transform: "translate(-50%, 0)",
                  }}
                ></Box>
              </Box>
              {/* Copyright stamp at the bottom */}
            </SwipeableDrawer>
          </Toolbar>
        </Container>
      </AppBar>
    </React.Fragment>
  );
}
