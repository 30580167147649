import * as React from "react";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import DirectionsIcon from "@mui/icons-material/Directions";
import colors from "../Base/colors";

const Noop = () => {};

export default function SearchBar({
  placeholderText = "Search Music",
  onSearch = Noop,
}) {
   const [val,setVal] = React.useState("")
  return (
    <Paper
      component="form"
      sx={{ display: "flex", width: "100%", position: "sticky" }}
      onSubmit={(ev)=>{
        ev.preventDefault()
        onSearch(val)
      }}
    >
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder={placeholderText}
        inputProps={{ "aria-label": "search google maps" }}
        value={val}
        onChange={(ev)=>{
            setVal(ev.target.value)
        }}
        onSubmit={(ev)=>{
            ev.preventDefault()
            onSearch(val)
        }}
      />
      <IconButton
        type="button"
        sx={{ p: "10px", color: colors.PRIMARY }}
        aria-label="search"
        onSubmit={(ev) => {
          ev.preventDefault();
          console.log("searching");
          onSearch(val)
        }}
        onClick={()=>{
            onSearch(val)
        }}
      >
        <SearchIcon />
      </IconButton>
    </Paper>
  );
}
