import * as React from "react";
import { Alert, Snackbar } from "@mui/material";
import AccordionGroup from "@mui/joy/AccordionGroup";
import Accordion from "@mui/joy/Accordion";
import AccordionDetails from "@mui/joy/AccordionDetails";
import AccordionSummary from "@mui/joy/AccordionSummary";
import fonts from "../Base/fonts";
import colors from "../Base/colors";
import { Typography } from "@mui/material";
import EventIcon from "@mui/icons-material/Event";
import Avatar from "@mui/joy/Avatar";
import ListItemContent from "@mui/joy/ListItemContent";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import HotelIcon from "@mui/icons-material/Hotel";
import ChildCareIcon from "@mui/icons-material/ChildCare";
import CheckroomIcon from "@mui/icons-material/Checkroom";
import DoDisturbIcon from "@mui/icons-material/DoDisturb";

const faq = [
  {
    icon: <EventIcon style={{ color: colors.ALT }} />,
    title: "What if I don’t RSVP?",
    description: (
      <Typography sx={{ fontFamily: fonts.NORMAL }}>
        To help us with planning, we kindly ask you to RSVP by the date provided
        on your formal invitation.
        <br></br>
        <br></br>
        If you are having trouble RSVPing, please contact the bride and groom.
        Unfortunately if we <strong>do not receive </strong>your RSVP by the
        deadline noted on the invitation, we will have to mark you as{" "}
        <strong> not attending</strong>.<br></br>
        <br></br>
        We will miss celebrating with you, but we need to finalize our guest
        count for the venue. We cannot make any exceptions. We hope you
        understand!
      </Typography>
    ),
  },
  {
    icon: <PersonAddAlt1Icon style={{ color: colors.ALT }} />,
    title: "Can I bring plus one?",
    description:
      "We are having an intimate wedding and our wedding is strictly invitation only. If you have a plus one indicated on your formal invitation, then you are welcome to bring a plus one. Unfortunately we cannot accommodate other extra guests due to venue capacity.",
  },
  {
    icon: <DirectionsCarIcon style={{ color: colors.ALT }} />,
    title: "When Should I arrive?",
    description:
      "We recommend you arriving 15-20 minutes before the start of the ceremony to make sure everyone is on time. We kindly ask that if arriving anytime after the ceremony starts, you head to the reception area to decrease interruptions to the ceremony.",
  },
  {
    icon: <CardGiftcardIcon style={{ color: colors.ALT }} />,
    title: "Do you have a registry? Can I bring a gift?",
    description: ({ setSnackBarText }) => {
      return (
        <div>
          <Typography sx={{ fontFamily: fonts.NORMAL }}>
            {" "}
            Your presence at the wedding is enough and a gift is not necessary.  If you wish to gift us something, we would greatly appreciate a contribution toward our dream honeymoon. Any contribution towards the honeymoon fund will help us with hotel airfare, food and some excursions we would love to do. Please use this
            <Typography
              sx={{
                fontFamily: fonts.NORMAL,
                textDecoration: "underline",
                color: colors.ALT,
              }}
              onClick={() => {
                window
                  .open(
                    "https://www.honeyfund.com/site/baderkhan-andino-04-21-2024",
                    "_blank"
                  )
                  .focus();
              }}
            >
              Link
            </Typography>
           
          </Typography>
        </div>
      );
    },
  },
  {
    icon: <HotelIcon style={{ color: colors.ALT }} />,
    title: "Are there any preferred Accommodations?",
    description: ({ setSnackBarText }) => {
      return (
        <div>
          <Typography sx={{ fontFamily: fonts.NORMAL }}>
            {" "}
            The bride and groom have reserved a room block at
            <Typography
              sx={{
                fontFamily: fonts.NORMAL,
                textDecoration: "underline",
                color: colors.ALT,
              }}
              onClick={() => {
                window
                  .open(
                    "https://www.marriott.com/events/start.mi?id=1701199260773&key=GRP",
                    "_blank"
                  )
                  .focus();
              }}
            >
              Aloft Downtown.
            </Typography>
            <br></br>
            There will also be an after party held at the same hotel if you wish
            to continue the party with the bride and groom!
          </Typography>
        </div>
      );
    },
  },
  {
    icon: <ChildCareIcon style={{ color: colors.ALT }} />,
    title: "Are Kids Invited?",
    description:
      "Although we adore your little ones, we have decided to keep our weddings an adults-only event. The only exception will be the little ones in our bridal party/immediate family. We hope you understand and enjoy the night off!",
  },
  {
    icon: <CheckroomIcon style={{ color: colors.ALT }} />,
    title: "Is there a dress code?",
    description: (
      <Typography sx={{ fontFamily: fonts.NORMAL }}>
        We would love to see our family and friends dress up with us! We are
        requesting spring cocktail attire. We recommend wearing something
        comfortable, yet appropriate. No black tie is required but please no
        blue jeans!
        <br></br>
        <br></br>
        Please refrain from wearing colors traditionally reserved for the bride.
        This includes white, ivory, or cream colors.
        <br></br>
        <br></br>
        Additionally, the bridesmaids will be wearing a dusty blue color and we
        ask our guests to please try and stay away from this color as we want
        the bridal party to stand out. Thank you in advance!
      </Typography>
    ),
  },
];

export default function FAQ() {
  const [isCopied, setIsCopied] = React.useState("");
  const [faqItems, setFaqItems] = React.useState(faq);
  return (
    <React.Fragment>
      <AccordionGroup
        disableDivider
        transition={{
          initial: "0.5s ease-out",
          expanded: "0.5s ease",
        }}
        color={"success"}
      >
        {faqItems.map(({ title, description, icon }) => {
          return (
            <Accordion
              variant="plain"
              sx={{ color: colors.PRIMARY }}
              defaultExpanded={false}
            >
              <AccordionSummary
                sx={{
                  fontFamily: fonts.NORMAL,
                  fontSize: "1.25rem",
                  color: colors.PRIMARY,
                }}
              >
                <Avatar color="">{icon}</Avatar>
                <ListItemContent>
                  <Typography
                    fontWeight={400}
                    fontSize={"1.25rem"}
                    fontFamily={fonts.NORMAL}
                    color={colors.PRIMARY}
                  >
                    {title}
                  </Typography>
                </ListItemContent>
              </AccordionSummary>
              <AccordionDetails sx={{ fontFamily: fonts.NORMAL }}>
                {typeof description == "function"
                  ? description({ setSnackBarText: setIsCopied })
                  : description}
              </AccordionDetails>
            </Accordion>
          );
        })}
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
      </AccordionGroup>
      <Snackbar
        open={isCopied}
        onClose={() => {
          setIsCopied(false);
        }}
        variant="success"
        autoHideDuration={3 * 1000}
      >
        <Alert
          variant="filled"
          onClose={() => {
            setIsCopied(false);
          }}
          severity="info"
          sx={{ width: "100%", backgroundColor: colors.ALT }}
        >
          {isCopied}
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
}
